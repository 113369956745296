import { LOCAL_STATE_KEYS } from './src/enums/local-state-keys'

export const onPreRouteUpdate = () => {
  if (location.hash === '#download') {
    window.addEventListener('popstate', function () {
      const isRedirectHomePage = window.localStorage.getItem(
        LOCAL_STATE_KEYS.REDIRECT_HOME_PAGE
      )

      if (isRedirectHomePage) {
        window.location.href = '/'
      }

      window.localStorage.removeItem(LOCAL_STATE_KEYS.REDIRECT_HOME_PAGE)
    })
  }
}
